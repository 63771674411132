@import "src/customization/bootstrap-modules.scss";

.reviews {
  &-content {
    min-height: 358px;

    @include media-breakpoint-up(md) {
      min-height: 420px;
    }
  }

  &-container {
    overflow-x: scroll;
    overflow-y: visible;

    gap: 16px;
    padding-right: 16px;

    &::-webkit-scrollbar {
      display: none;
    }

    @include media-breakpoint-up(md) {
      gap: 24px;
      padding-right: 24px !important;
    }
  }

  &-item {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.10);
    border-radius: 4px;
    width: 269px;
    min-width: 269px;
    height: 311px;
    overflow-y: hidden;

    @include media-breakpoint-up(md) {
      border-radius: 4px;
      min-width: 384px;
      width: 384px;
      height: 438px;
    }

    &-icon {
      path {
        fill: $white;
      }

      @include media-breakpoint-up(md) {
        width: 24px;
        height: 24px;
      }
    }

    &-text {
      &, * {
        margin: 0 !important;
        white-space: normal !important;
      }
    }

    &-position {
      text-wrap: pretty !important;
    }
  }

  &-circle {
    top: 0;
    left: 0;
    width: 273px;
    height: 273px;

    @include media-breakpoint-up(md) {
      width: 471px;
      height: 471px;
    }
  }
}

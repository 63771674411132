// main design colors

$primary:                       #3396AA;

$flamingo:                      #E45881;
$yellow:                        #FFD956;
$light-blue:                    #85b6FF;
$lilac:                         #BA68C8;
$menthol:                       #80C5CC;
$purple:                        #7367F0;
$topaz:                         #67D7F0;
$green:                         #55E564;
$dark-blue:                     #44B5F4;
$orange:                        #FFAC30;
$dark-orange:                   #F1651B;
$blue:                          #00B2FF;
$gray-blue:                     #4094DA;

$night-100:                     #F2F3F7;
$night-200:                     #EAEDF5;
$night-300:                     #C0C3CE;
$night-500:                     #6C7283;
$night-900:                     #2C2F3C;
$black:                         $night-900;
$white:                         #FFFFFF;

$primary-5:                     rgba($primary, .05);
$primary-4:                     rgba($primary, .04);
$primary-10:                    rgba($primary, .10);
$flamingo-10:                   rgba($flamingo, .10);
$yellow-10:                     rgba($yellow, .10);
$light-blue-10:                 rgba($light-blue, .10);
$lilac-10:                      rgba($lilac, .10);
$menthol-10:                    rgba($menthol, .10);
$purple-10:                     rgba($purple, .10);
$topaz-10:                      rgba($topaz, .10);
$green-10:                      rgba($green, .10);
$blue-10:                       rgba($blue, .10);
$dark-blue-10:                  rgba($dark-blue, .10);
$gray-blue-10:                  rgba($gray-blue, .10);
$orange-10:                     rgba($orange, .10);
$dark-orange-10:                rgba($dark-orange, .10);
$night-10:                      rgba($night-900, .10);
$white-50:                      rgba($white, .50);

$theme-colors: (
        'flamingo':                   $flamingo,
        'yellow':                     $yellow,
        'light-blue':                 $light-blue,
        'lilac':                      $lilac,
        'mentol':                     $menthol,
        'purple':                     $purple,
        'topaz':                      $topaz,
        'bright-green':               $green,
        'dark-blue':                  $dark-blue,
        'blue':                       $blue,
        'gray-blue':                  $gray-blue,
        'orange':                     $orange,
        'dark-orange':                $dark-orange,
        'flamingo-10':                $flamingo-10,
        'yellow-10':                  $yellow-10,
        'light-blue-10':              $light-blue-10,
        'lilac-10':                   $lilac-10,
        'mentol-10':                  $menthol-10,
        'purple-10':                  $purple-10,
        'topaz-10':                   $topaz-10,
        'blue-10':                    $blue-10,
        'green-10':                   $green-10,
        'dark-blue-10':               $dark-blue-10,
        'gray-blue-10':               $gray-blue-10,
        'orange-10':                  $orange-10,
        'dark-orange-10':             $dark-orange-10,
        'primary':                    $primary,
        'night-900':                  $night-900,
        'night-500':                  $night-500,
        'primary-5':                  $primary-5,
        'primary-10':                 $primary-10,
        'night-10':                   $night-10,
);

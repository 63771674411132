.main-screen {
  &-icons {
    top: 0;

    &-frame {
      top: 0;
      transition: opacity 1s;
      z-index: 0;

      &-0 {
        &-icon-1 {
          left: 16px;
          top: 191px;
          width: 32px;
          height: 32px;

          @include media-breakpoint-up(sm) {
            left: 53px;
            top: 37px;
          }

          @include media-breakpoint-up(md) {
            top: 47px;
            left: 24px;
            width: 45px;
            height: 45px;
          }

          @include media-breakpoint-up(lg) {
            top: 107px;
            left: 130px;
          }
        }

        &-icon-2 {
          display: none;

          @include media-breakpoint-up(md) {
            display: block;
            top: 395px;
            left: 40px;
          }

          @include media-breakpoint-up(lg) {
            top: 165px;
            left: 290px;
          }
        }

        &-icon-3 {
          left: 16px;
          top: 565px;
          width: 49px;
          height: 49px;

          @include media-breakpoint-up(sm) {
            left: 30px;
            top: 585px;
          }

          @include media-breakpoint-up(md) {
            left: 83px;
            top: 636px;
            width: 53px;
            height: 53px;
          }

          @include media-breakpoint-up(lg) {
            left: 195px;
            top: 636px;
          }
        }

        &-icon-4 {
          display: none;

          @include media-breakpoint-up(md) {
            display: block;
            top: 29px;
            right: 147px;
          }

          @include media-breakpoint-up(lg) {
            top: 89px;
            right: 285px;
          }
        }

        &-icon-5 {
          right: 16px;
          top: 259px;
          width: 32px;
          height: 32px;

          @include media-breakpoint-up(sm) {
            top: 109px;
            right: 19px;
          }

          @include media-breakpoint-up(md) {
            right: 116px;
            top: 135px;
          }

          @include media-breakpoint-up(lg) {
            right: 400px;
            top: 230px
          }
        }

        &-icon-6 {
          display: none;

          @include media-breakpoint-up(md) {
            display: block;
            right: 50px;
            top: 542px;
          }

          @include media-breakpoint-up(lg) {
            right: 139px;
            top: 542px;
          }
        }
      }

      &-1 {
        &-icon-1 {
          left: 16px;
          top: 191px;
          width: 32px;
          height: 32px;

          @include media-breakpoint-up(sm) {
            left: 53px;
            top: 37px;
          }

          @include media-breakpoint-up(md) {
            top: 140px;
            left: 30px;
            width: 40px;
            height: 40px;
          }

          @include media-breakpoint-up(lg) {
            top: 53px;
            left: 311px;
            width: 40px;
            height: 40px;
          }
        }

        &-icon-2 {
          display: none;

          @include media-breakpoint-up(md) {
            display: block;
            top: 593px;
            left: 53px;
          }

          @include media-breakpoint-up(lg) {
            display: block;
            top: 165px;
            left: 104px;
          }
        }

        &-icon-3 {
          left: 16px;
          top: 565px;
          width: 49px;
          height: 49px;

          @include media-breakpoint-up(sm) {
            left: 30px;
            top: 585px;
          }

          @include media-breakpoint-up(md) {
            top: 536px;
            left: auto;
            right: 25px;
          }

          @include media-breakpoint-up(lg) {
            top: 549px;
            left: auto;
            right: 183px;
          }
        }

        &-icon-4 {
          display: none;

          @include media-breakpoint-up(md) {
            display: block;
            top: 132px;
            left: auto;
            right: 142px;
          }

          @include media-breakpoint-up(lg) {
            display: block;
            top: 200px;
            left: auto;
            right: 120px;
          }
        }

        &-icon-5 {
          right: 16px;
          top: 259px;
          width: 32px;
          height: 32px;

          @include media-breakpoint-up(sm) {
            right: 19px;
            top: 109px;
          }

          @include media-breakpoint-up(md) {
            right: 198px;
            top: 40px;
            width: 35px;
            height: 35px;
          }

          @include media-breakpoint-up(lg) {
            right: 234px;
            top: 127px;
            width: 35px;
            height: 35px;
          }
        }

        &-icon-6 {
          display: none;

          @include media-breakpoint-up(md) {
            display: block;

            left: 116px;
            top: 620px;
          }

          @include media-breakpoint-up(lg) {
            display: block;

            left: 226px;
            top: 598px;
          }
        }
      }

      &-2 {
        &-icon-1 {
          left: 16px;
          top: 191px;
          width: 32px;
          height: 32px;

          @include media-breakpoint-up(sm) {
            left: 53px;
            top: 37px;
          }

          @include media-breakpoint-up(md) {
            display: none;
          }

          @include media-breakpoint-up(lg) {
            display: block;
            left: 380px;
            top: 213px;
          }
        }

        &-icon-2 {
          display: none;

          @include media-breakpoint-up(md) {
            display: block;
            width: 52px;
            height: 52px;
            left: 26px;
            top: 134px;

            rect {
              fill: #4094DA;
            }

            path {
              stroke: #4094DA;
            }
          }

          @include media-breakpoint-up(lg) {
            left: 93px;
            top: 134px;
          }
        }

        &-icon-3 {
          left: 16px;
          top: 565px;
          width: 49px;
          height: 49px;

          @include media-breakpoint-up(sm) {
            left: 30px;
            top: 585px;
          }

          @include media-breakpoint-up(md) {
            top: 536px;
            left: auto;
            right: 25px;
          }

          @include media-breakpoint-up(lg) {
            top: 536px;
            left: auto;
            right: 108px;
          }
        }

        &-icon-4 {
          display: none;

          @include media-breakpoint-up(md) {
            display: block;

            top: 134px;
            left: auto;
            right: 145px;
          }

          @include media-breakpoint-up(lg) {
            top: 134px;
            left: auto;
            right: 225px;
          }
        }

        &-icon-5 {
          right: 16px;
          top: 259px;
          width: 32px;
          height: 32px;

          @include media-breakpoint-up(sm) {
            right: 19px;
            top: 109px;
          }

          @include media-breakpoint-up(md) {
            top: 593px;
            left: 55px;
          }

          @include media-breakpoint-up(lg) {
            top: 593px;
            left: 120px;
          }
        }

        &-icon-6 {
          display: none;

          @include media-breakpoint-up(md) {
            display: block;

            top: 639px;
            left: 130px;
          }

          @include media-breakpoint-up(lg) {
            display: block;

            top: 639px;
            left: 198px;
          }
        }
      }
    }
  }
}

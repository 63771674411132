@import "src/customization/bootstrap-modules.scss";

.partners-invite {
  &-description {
    @include media-breakpoint-up(lg) {
      max-width: 500px;
    }
  }

  &-icon-left {
    width: 102px;
    height: 102px;
    left: -60px;
    top: -30px;

    @include media-breakpoint-up(md) {
      width: 151px;
      height: 151px;

      left: -80px;
      top: 30px;
    }

    @include media-breakpoint-up(lg) {
      top: 40px;
      left: 60px;
    }
  }

  &-icon-right {
    width: 93px;
    height: 93px;
    right: -32px;
    bottom: -11px;

    @include media-breakpoint-up(sm) {
      right: -42px;
      bottom: -31px;
    }

    @include media-breakpoint-up(md) {
      width: 151px;
      height: 151px;

      right: -28px;
      bottom: 2px;
    }

    @include media-breakpoint-up(lg) {
      right: 200px;
      bottom: 40px;
    }

    path {
      fill: #1F8296;
      fill-opacity: 1;
    }
  }

  &-button.ant-btn.ant-btn-lg {
    height: 36px;

    @include media-breakpoint-up(md) {

      height: 64px;
    }
  }
}

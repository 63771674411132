@import "src/customization/bootstrap-modules.scss";

.technical-capabilities-support {
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: bottom;
  background-image: url('../../../../public/images/technical-capabilities/support.webp');

  @include media-breakpoint-up(lg) {
    background-position-y: center;
  }

  .trafory-logo {
    width: 307px;
    height: auto;

    @include media-breakpoint-up(md) {
      width: 509px;
    }

    @include media-breakpoint-up(lg) {
      width: 567px;
    }

    path {
      fill: $primary;
    }
  }

  &-icon {
    position: absolute;
    display: none;

    &-1 {
      display: block;
      left: 16px;
      top: 190px;
      width: 32px;
      height: 32px;

      @include media-breakpoint-up(sm) {
        left: 49px;
        top: 37px;
      }

      @include media-breakpoint-up(md) {
        width: 45px;
        height: 45px;
        left: 24px;
        top: 7px;
      }

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    &-2 {
      display: block;
      right: 16px;
      top: 200px;
      width: 32px;
      height: 32px;

      @include media-breakpoint-up(sm) {
        width: 49px;
        height: 49px;
        right: auto;
        top: auto;
        left: 55px;
        bottom: 92px
      }

      @include media-breakpoint-up(md) {
        left: 84px;
        bottom: 60px
      }

      @include media-breakpoint-up(lg) {
        left: 550px;
        bottom: 200px
      }
    }

    @include media-breakpoint-only(md) {
      &-4 {
        display: block;
        top: 325px;
        left: 42px;
      }
    }

    @include media-breakpoint-up(lg) {
      &-5 {
        display: block;
        bottom: 100px;
        left: 200px;
      }
    }
  }

  .bitrix-form {
    flex-shrink: 0;

    @include media-breakpoint-up(sm) {
      width: 328px;
    }

    @include media-breakpoint-up(md) {
      width: 434px;
    }

    @include media-breakpoint-up(lg) {
      width: 615px;
    }
  }
}

@import "./variables/colors";
@import "./variables/grid-breakpoins.scss";

$h1-font-size:                  50px;
$h1-line-height:                1.2;

$h2-font-size:                  42px;
$h2-line-height:                36px;

$h3-font-size:                  30px;
$h3-line-height:                1.2;

$h4-font-size:                  24px;
$h5-font-size:                  20px;
$h6-font-size:                  16px;

$line-height-base:              1.2;

//Spaces
$spacer:                        1rem;
$spacers: (
  '0': 0,
  '0_25': ($spacer * .125),
  '0_5': ($spacer * .25),
  '0_75': ($spacer * .375),
  '1': ($spacer * .5),
  '1_25': ($spacer * .625),
  '1_5': ($spacer * .75),
  '1_75': ($spacer * .875),
  '2': $spacer,
  '2_25': ($spacer * 1.125),
  '2_5': ($spacer * 1.25),
  '2_75': ($spacer * 1.375),
  '3': ($spacer * 1.5),
  '3_25': ($spacer * 1.625),
  '3_5': ($spacer * 1.75),
  '3_75': ($spacer * 1.875),
  '4': ($spacer * 2),
  '4_25': ($spacer * 2.125),
  '4_5': ($spacer * 2.25),
  '4_75': ($spacer * 2.375),
  '5': ($spacer * 2.5),
  '5_25': ($spacer * 2.625),
  '5_5': ($spacer * 2.75),
  '5_75': ($spacer * 2.875),
  '6': ($spacer * 3),
  '6_25': ($spacer * 3.125),
  '6_5': ($spacer * 3.25),
  '6_75': ($spacer * 3.375),
  '7': ($spacer * 3.5),
  '7_25': ($spacer * 3.625),
  '7_5': ($spacer * 3.75),
  '7_75': ($spacer * 3.875),
  '8': ($spacer * 4),
  '8_25': ($spacer * 4.125),
  '8_5': ($spacer * 4.25),
  '8_75': ($spacer * 4.375),
  '9': ($spacer * 4.5),
  '9_25': ($spacer * 4.625),
  '9_5': ($spacer * 4.75),
  '9_75': ($spacer * 4.875),
  '10': ($spacer * 5),
  '10_25': ($spacer * 5.125),
  '10_5': ($spacer * 5.25),
  '10_75': ($spacer * 5.375),
  '11': ($spacer * 5.5),
  '11_25': ($spacer * 5.625),
  '11_5': ($spacer * 5.75),
  '11_75': ($spacer * 5.875),
  '12': ($spacer * 6),
  '12_25': ($spacer * 6.125),
  '12_5': ($spacer * 6.25),
  '12_75': ($spacer * 6.375),
  '13': ($spacer * 6.5),
  '13_5': ($spacer * 6.75),
  '14': ($spacer * 7),
  '15': ($spacer * 7.5),
  '15_75': ($spacer * 7.875),
  '16_25': ($spacer * 8.125),
  '17_25': ($spacer * 8.625),
  '17_5': ($spacer * 8.75),
  '18': ($spacer * 9),
  '18_5': ($spacer * 9.25),
  '20': ($spacer * 10),
  '20_25': ($spacer * 10.125),
  '23_25': ($spacer * 11.625),
  '24': ($spacer * 12),
  '25': ($spacer * 12.5),
);

$utilities: (
    "bg-opacity": (
        css-var: true,
        class: bg-opacity,
        values: (
            4: .04,
            5: .05,
            10: .1,
            20: .2,
            25: .25,
            50: .5,
            75: .75,
            90: .9,
            100: 1
        )
    ),
    "border-opacity": (
        css-var: true,
        class: border-opacity,
        values: (
            4: .04,
            5: .05,
            10: .1,
            20: .2,
            25: .25,
            50: .5,
            75: .75,
            100: 1
        )
    ),
);

@import '~bootstrap';

@import "src/customization/bootstrap-modules";
@import "icon-styles/main-screen-icons";
@import "icon-styles/main-screen-icons-home";

@mixin animate ($duration, $position: 0) {
  animation: photo-transition $duration infinite;
  animation-timing-function: linear;

  @keyframes photo-transition {
    0% {
      transform: translate(0 + $position, 0 + $position);
    }

    20% {
      transform: translate(75%, 0 + $position);
    }

    40% {
      transform: translate(150%, 75%);
    }

    60% {
      transform: translate(100% + $position, 100%);
    }

    80% {
      transform: translate(0 + $position, 75%);
    }

    100% {
      transform: translate(0 + $position, 0 + $position);
    }
  }
}

.main-screen {
  overflow: hidden;

  @include media-breakpoint-only(sm) {
    height: 645px;
  }

  &-logo {
    width: 307px;
    height: auto;

    @include media-breakpoint-up(md) {
      width: 505px;
    }

    @include media-breakpoint-up(lg) {
      width: 567px;
    }

    path {
      fill: $primary;
    }
  }

  &-title {
    top: -16px;
  }

  &-description {
    text-shadow: 1px 1px 0 white,
    -1px -1px 0 white,
    1px -1px 0 white,
    -1px 1px 0 white;
    position: relative;
    z-index: 2;

    @include media-breakpoint-down(sm) {
      max-width: 328px;
    }

    @include media-breakpoint-up(md) {
      margin-top: -14px;
      margin-bottom: 2px;
      font-size: 20px;
      line-height: 1.5;
    }
  }

  &-frames {
    min-height: 280px;

    @include media-breakpoint-up(sm) {
      top: -30px;
      min-height: 500px;
    }

    @include media-breakpoint-up(md) {
      min-height: 440px;
      top: -85px
    }

    @include media-breakpoint-up(lg) {
      height: 540px;
    }

    &-item {
      transition: opacity 1s;
      margin-left: auto;
      margin-right: auto;
      height: 100%;
    }
  }

  &-animated-photos {
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;

    @include media-breakpoint-only(md) {
      width: calc(100% + 150px);
      left: -100px;
    }

    .animated-photo {
      width: 44px;
    }

    &-item-1 {
      left: 233px;
      top: 32px;
      @include animate(30s);
      animation-direction: reverse;
    }

    &-item-2 {
      left: 148px;
      top: 463px;
      @include animate(25s);
    }

    &-item-3 {
      right: 168px;
      top: 584px;
      @include animate(37s);
      animation-direction: reverse;
    }

    &-item-4 {
      top: 390px;
      right: 280px;
      @include animate(20s);
    }

    &-item-5 {
      top: 164px;
      right: 177px;
      @include animate(41s);
      animation-direction: reverse;
    }
  }
}

.main-screen.home-page {
  @include media-breakpoint-up(sm) {
    height: 465px;
  }

  @include media-breakpoint-up(md) {
    height: 600px;
    border-bottom: 1px solid rgba(51, 150, 170, 0.10);
  }

  @include media-breakpoint-up(lg) {
    height: 800px;
  }

  .main-screen-animated-photos {
    width: 100%;
    height: 100%;
    left: 0;

    &-item-1 {
      left: 407px;
      top: 32px;

      @include media-breakpoint-up(lg) {
        top: 534px;
        left: 413px;
      }
    }

    &-item-2 {
      left: 148px;
      top: 300px;

      @include media-breakpoint-up(lg) {
        top: 473px;
        left: 96px;
      }
    }

    &-item-3 {
      right: 100px;
      top: 500px;

      @include media-breakpoint-up(lg) {
        right: 142px;
        top: 641px;
      }
    }

    &-item-4 {
      top: 480px;
      right: 400px;

      @include media-breakpoint-up(lg) {
        left: 237px;
        top: 670px;
        right: auto;
      }
    }

    &-item-5 {
      top: 48px;
      right: 110px;
    }
  }

  .main-screen-frames {
    min-height: 260px;

    @include media-breakpoint-up(sm) {
      position: absolute !important;
      top: 16px;
      min-height: 440px;

      &-item {
        right: 0;
      }
    }

    @include media-breakpoint-up(md) {
      top: 50px;
      min-height: 530px;

      &-item {
        right: 20px;
      }
    }

    @include media-breakpoint-up(lg) {
      top: 152px;
      min-height: 595px;

      &-item {
        right: 70px;
      }
    }
  }
}

.main-screen:not(.home-page) {
  height: 100%;

  .main-screen-frames-item {
    @include media-breakpoint-down(sm) {
      height: 95%;
    }
  }
}

@import "src/customization/bootstrap-modules";

.landing-tabs {
  &-navbar {
    overflow-x: scroll;
    color: $primary;

    &::-webkit-scrollbar {
      display: none;
    }

    &-item {
      height: 37px;
      white-space: pre;
      font-size: $font-size-base;

      &.active {
        @include media-breakpoint-up(md) {
          &::before {
            content: '';
            bottom: 0;
            left: 0;
            position: absolute;
            width: 100%;
            height: 3px;
            background-color: $primary;
          }
        }
      }

      &:not(.active).animated-tab {
        animation: linear 5s infinite alternate animate-opacity;
      }

      @include media-breakpoint-down(md) {
        padding: 11px;
        border: 1px solid $primary;
        border-radius: 4px;

        &.active {
          color: $white;
          background-color: $primary;
        }
      }
    }
  }

  .tab-panes {
    .tab-pane {
      overflow: hidden;
      width: 0;
      height: 0;
      transition: transform .3s;
      transform: translateX(100%);

      &.active {
        width: 100%;
        height: 503px;
        transform: translateX(0);

        @include media-breakpoint-up(sm) {
          height: 552px;
        }

        @include media-breakpoint-up(md) {
          height: 450px;
        }
      }
    }
  }
}

@keyframes animate-opacity {
  50% {
    opacity: .5;
  }
}

@import "src/customization/bootstrap-modules";

.employee-info-block {
  &-item {
    @include media-breakpoint-up(sm) {
      gap: 12px;
    }

    @include media-breakpoint-up(md) {
      gap: 0;
    }

    @include media-breakpoint-up(lg) {
      gap: 67px;
    }

    &-image {
      @include media-breakpoint-up(sm) {
        width: 360px;
      }

      @include media-breakpoint-up(md) {
        width: 520px;
      }

      @include media-breakpoint-up(lg) {
        width: 560px;
      }
    }

    &-text {
      @include media-breakpoint-up(md) {
        line-height: 1.8 !important;
      }
    }
  }
}

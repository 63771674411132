@font-face {
  font-family: 'Ubuntu';
  src: url('../../public/fonts/Ubuntu/Ubuntu-Regular.eot');
  src: local('Ubuntu Regular'), local('Ubuntu-Regular'),
  url('../../public/fonts/Ubuntu/Ubuntu-Regular.eot?#iefix') format('embedded-opentype'),
  url('../../public/fonts/Ubuntu/Ubuntu-Regular.woff') format('woff'),
  url('../../public/fonts/Ubuntu/Ubuntu-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../../public/fonts/Ubuntu/Ubuntu-Light.eot');
  src: local('Ubuntu Light'), local('Ubuntu-Light'),
  url('../../public/fonts/Ubuntu/Ubuntu-Light.eot?#iefix') format('embedded-opentype'),
  url('../../public/fonts/Ubuntu/Ubuntu-Light.woff') format('woff'),
  url('../../public/fonts/Ubuntu/Ubuntu-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../../public/fonts/Ubuntu/Ubuntu-LightItalic.eot');
  src: local('Ubuntu Light Italic'), local('Ubuntu-LightItalic'),
  url('../../public/fonts/Ubuntu/Ubuntu-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../../public/fonts/Ubuntu/Ubuntu-LightItalic.woff') format('woff'),
  url('../../public/fonts/Ubuntu/Ubuntu-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../../public/fonts/Ubuntu/Ubuntu-Medium.eot');
  src: local('Ubuntu Medium'), local('Ubuntu-Medium'),
  url('../../public/fonts/Ubuntu/Ubuntu-Medium.eot?#iefix') format('embedded-opentype'),
  url('../../public/fonts/Ubuntu/Ubuntu-Medium.woff') format('woff'),
  url('../../public/fonts/Ubuntu/Ubuntu-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../../public/fonts/Ubuntu/Ubuntu-Italic.eot');
  src: local('Ubuntu Italic'), local('Ubuntu-Italic'),
  url('../../public/fonts/Ubuntu/Ubuntu-Italic.eot?#iefix') format('embedded-opentype'),
  url('../../public/fonts/Ubuntu/Ubuntu-Italic.woff') format('woff'),
  url('../../public/fonts/Ubuntu/Ubuntu-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../../public/fonts/Ubuntu/Ubuntu-Bold.eot');
  src: local('Ubuntu Bold'), local('Ubuntu-Bold'),
  url('../../public/fonts/Ubuntu/Ubuntu-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../public/fonts/Ubuntu/Ubuntu-Bold.woff') format('woff'),
  url('../../public/fonts/Ubuntu/Ubuntu-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../../public/fonts/Ubuntu/Ubuntu-BoldItalic.eot');
  src: local('Ubuntu Bold Italic'), local('Ubuntu-BoldItalic'),
  url('../../public/fonts/Ubuntu/Ubuntu-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../../public/fonts/Ubuntu/Ubuntu-BoldItalic.woff') format('woff'),
  url('../../public/fonts/Ubuntu/Ubuntu-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../../public/fonts/Ubuntu/Ubuntu-MediumItalic.eot');
  src: local('Ubuntu Medium Italic'), local('Ubuntu-MediumItalic'),
  url('../../public/fonts/Ubuntu/Ubuntu-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('../../public/fonts/Ubuntu/Ubuntu-MediumItalic.woff') format('woff'),
  url('../../public/fonts/Ubuntu/Ubuntu-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

* {
  box-sizing: border-box;
  font-family: 'Ubuntu';
}

body {
  .bitrix-form {
    * {
      white-space: normal !important;
    }
  }
}

#root {
  * {
    white-space: pre-line;
  }
}

.app {
  min-height: 100vh;
}

.bitrix-form {
  &-demo {
    min-height: 700px;
  }
}

.b24-widget-button-inner-container {
  display: flex;
}

.b24-widget-button-block {
  display: flex;
}

.b24-widget-button-inner-block {
  width: 100%;
}

.cookies-backdrop-shadow {
  box-shadow: 0 -5px 5px rgba(black, 0.05);
}

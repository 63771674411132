@import '../../bootstrap-modules.scss';

.ant-btn {
  &.ant-btn-primary {
    padding: 6px 12px;
    height: 29px;
    font-size: 12px;

    @include media-breakpoint-up(sm) {
      padding: 7px 10px;
      height: 34px;
      font-size: 14px;
    }

    @include media-breakpoint-up(md) {
      padding: 9px 22px;
      height: 40px;
      font-size: 16px;
    }
  }

  &.ant-btn-link {
    height: auto;
    font-size: 14px;
    border-width: 0;
    text-decoration: none;

    @include media-breakpoint-up(md) {
      font-size: 16px;
    }
  }

  &.ant-btn-lg {
    height: 40px;
    padding: 0 32px;
    font-size: 14px;

    @include media-breakpoint-up(md) {
      height: 64px;
      font-size: 24px;
      padding: 0 64px;
    }
  }
}

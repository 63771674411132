@import 'src/customization/bootstrap-modules.scss';

.posts {
  &-item {
    box-shadow: 0 0 10px 0 rgba(34, 39, 47, 0.10);

    overflow-x: hidden;

    &-image {
      width: 100%;

      @include media-breakpoint-up(sm) {
        object-fit: cover;
        height: 100%;
      }

      @include media-breakpoint-up(md) {
        max-height: 200px;
      }
    }

    &-title {
      flex: 1 1 auto
    }
  }

  &-container {
    gap: 16px;

    @include media-breakpoint-up(lg) {
      gap: 24px;
    }
  }

  &-item-link {
    min-width: 0;
    @include media-breakpoint-up(sm) {
      flex: 1 1 auto;
      flex-basis: 40%;
    }

    @include media-breakpoint-up(md) {
      flex-basis: 30%;
    }
  }
}

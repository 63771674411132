@import "src/customization/bootstrap-modules";

@mixin set-location-map-background ($addition-url: '') {
  $main-url: "../../../public/images/footer/location-map/location-map";

  background-image: url("#{$main-url}#{$addition-url}.jpg");

  @media (-webkit-min-device-pixel-ratio: 2) {
    background-image: url("#{$main-url}#{$addition-url}@2x.jpg");
  }

  @media (-webkit-min-device-pixel-ratio: 3) {
    background-image: url("#{$main-url}#{$addition-url}@3x.jpg");
  }
}

.landing-footer {
  padding: 16px 0;
  font-size: 16px;

  @include media-breakpoint-up(md) {
    padding: 80px 60px 200px;

    &-inner-container {
      max-width: 1200px;
    }
  }

  .info-link-docs_pznb {
    @include media-breakpoint-down(sm) {
      order: -1;
    }
  }

  &-photos {
    width: 290px;

    @include media-breakpoint-up(md) {
      width: auto;
    }

    &-item {
      width: 70px;

      @include media-breakpoint-up(md) {
        width: 110px;
      }
    }

    @include media-breakpoint-up(sm) {
      width: 313px;
    }

    @include media-breakpoint-up(md) {
      width: 390px;
    }

    @include media-breakpoint-up(lg) {
      width: 420px;
    }
  }

  &-contacts {
    width: 225px;

    &-item-location {
      margin-top: -8px;
    }


    @include media-breakpoint-up(md) {
      grid-template:  '1 1'
                      '1 1';

      grid-template-columns: 44% 56%;
      grid-template-rows: 56% 44%;

      height: 90px;
      width: 115%;

      @include media-breakpoint-up(lg) {
        width: 100%;
        height: 107px;
      }

      &-item-time {
        order: 1;
      }

      &-item-mail {
        order: 2;
      }

      &-item-phone {
        order: 3;
      }

      &-item-location {
        order: 4;
      }
    }

    &-item-location &-item-text {
      line-height: 1.3;
      text-align: start;
      white-space: pre;
    }
  }

  &-location-map {
    @include set-location-map-background();
    padding-bottom: 90%;
    padding-right: 100%;
    background-size: 100%;
    height: 327px;

    @include media-breakpoint-up(sm) {
      width: 334px;
      height: 271px;
      padding-right: 0;
      padding-bottom: 0;
    }

    @include media-breakpoint-up(md) {
      border-radius: 4px;
      background-size: cover;
      width: 443px;
      height: 249px;

      @include set-location-map-background('-desktop');
    }

    @include media-breakpoint-up(lg) {
      background-size: cover;
      width: 605px;
      height: 340px;
    }

    &-button {
      bottom: 40px;
      right: 48px;

      @include media-breakpoint-up(sm) {
        bottom: 24px;
        right: 32px;
      }

      @include media-breakpoint-up(md) {
        bottom: 57px;
        right: 40px;
      }
    }
  }

  &-bottom {
    &-app-link {
      height: 30px !important;
    }
    .trafory-socials-link {
      .filled-path {
       fill: white;
      }

      .stroke-path {
        stroke: white;
        stroke-width: 1.2px;
      }
    }
  }

  &.blog-page {
    .trafory-socials-link {
      .filled-path {
        fill: $primary;
      }

      .stroke-path {
        stroke: $primary;
      }
    }
  }
}

@import "src/customization/bootstrap-modules";

.your-benefits-tabs {
  .tab-panes {
    min-width: 360px;
    width: 360px;
    border-radius: 4px;
    background-color: $white;
    margin-left: 13px;

    @include media-breakpoint-up(md) {
      min-width: 510px;
      width: 510px;
      margin-left: 24px;
    }

    @include media-breakpoint-up(lg) {
      min-width: 612px;
      width: 612px;
    }

    .tab-pane {
      overflow: hidden;
      width: 0;
      height: 0;
      transition: opacity 1s;
      opacity: 0;

      &.active {
        width: 100%;
        height: 499px;
        opacity: 1;

        @include media-breakpoint-up(md) {
          height: 450px;
        }

        @include media-breakpoint-up(lg) {
          height: 544px;
        }
      }

      &-text {
        @include media-breakpoint-up(sm) {
          border-top-width: 1px;
          border-top-style: solid;
          border-bottom-width: 0 !important;
        }
      }
    }
  }
}

@import "src/customization/bootstrap-modules.scss";

.integration-results {
  &-item {
    @include media-breakpoint-up(sm) {
      flex-basis: 48%;
      flex-grow: 1;

      &:last-child {
        flex-grow: 0;
      }
    }

    @include media-breakpoint-up(md) {
      &:nth-child(n+3):nth-child(-n+5) {
        flex-basis: 20%;
        flex-grow: 1;
      }
    }

    &-icon {
      width: 24px;
      height: 24px;

      path {
        fill: white;
      }
    }
  }

  &-items {
    gap: 10px;

    @include media-breakpoint-up(sm) {
      gap: 16px;
    }

    @include media-breakpoint-up(lg) {
      gap: 24px;
    }
  }
}

@import "src/customization/bootstrap-modules.scss";

.hr-instruments {
  &-item {
    @include media-breakpoint-up(sm) {
      gap: 32px
    }
    @include media-breakpoint-up(md) {
      gap: 38px
    }
    @include media-breakpoint-up(lg) {
      gap: 70px
    }

    li {
      line-height: 1.42;

      @include media-breakpoint-up(md) {
        line-height: 1.33;
      }

      @include media-breakpoint-up(lg) {
        line-height: 1.80;
      }

      &::marker {
        font-size: 10px;
        font-variant-numeric: oldstyle-nums;

        @include media-breakpoint-up(md) {
          font-size: 15px;
        }
      }
    }

    &-image {
      width: 320px;
      margin-bottom: auto;

      @include media-breakpoint-up(md) {
        width: 454px;
      }

      @include media-breakpoint-up(lg) {
        width: 462px;
      }
    }
  }
}

@import "src/customization/bootstrap-modules";

.trafory-purpose {
  @include media-breakpoint-up(md) {
    background-color: $primary-5;
    border-radius: 4px;

    &-description {
      max-width: 200px;
      margin-top: -23px;
    }
  }

  @include media-breakpoint-up(lg) {
    max-width: 1200px;
  }

  .purpose-tab-image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    flex-grow: 1;

    @include media-breakpoint-up(md) {
      flex-grow: 0;
      width: 390px;
      background-position-y: top;
    }

    @include media-breakpoint-up(lg) {
      width: 492px;
    }
  }

  &-tabs-info {
    svg {
      min-width: 24px;
      min-height: 24px;
    }
  }
}

@import "src/customization/bootstrap-modules.scss";

.trafory-roadmap {
  &-image {
    img {
      position: absolute;
      top: 110px;
      right: 0;
      height: 764px;
      z-index: 0;

      @include media-breakpoint-up(sm) {
        height: 621px;
        top: 0;
      }

      @include media-breakpoint-up(md) {
        top: 110px
      }
    }

    .roadmap-image-large {
      background-image: url("../../../public/images/trafory-roadmap/roadmap-large.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position-y: center;
      top: 110px;
      left: -5px;
      height: 300px;
      box-shadow: 0 0 40px 40px white inset;
    }
  }

  &-items {
    @include media-breakpoint-up(lg) {
      gap: 65px;
    }
  }

  &-item {
    @include media-breakpoint-up(lg) {
      flex-basis: 30%;

      &:nth-child(2) {
        order: 3;
      }

      &:nth-child(4) {
        order: 5;
      }
    }

    &-description {
      white-space: break-spaces;
      max-width: 270px;
      line-height: 1.4;

      @include media-breakpoint-up(sm) {
        max-width: 500px;
        line-height: 1.6;
      }

      @include media-breakpoint-up(lg) {
        line-height: 1.4;
        max-width: 400px;
      }
    }
  }
}

@import "src/customization/bootstrap-modules.scss";

@mixin set-experience-image ($locale: 'ru') {
  $main-url: '../../../public/images/trafory-experience/';

  background-image: url("#{$main-url}#{$locale}/xs.webp");
  height: 408px;

  @include media-breakpoint-up(sm) {
    background-image: url("#{$main-url}#{$locale}/sm.webp");
    height: 308px;
  }

  @include media-breakpoint-up(md) {
    background-image: url("#{$main-url}#{$locale}/md.webp");
    height: 467px;
  }

  @include media-breakpoint-up(lg) {
    background-image: url("#{$main-url}#{$locale}/lg.webp");
    height: 475px;
  }
}

.trafory-experience {
  &-image {
    @include set-experience-image();
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &-content {
    box-shadow: 0 10px 20px 0 rgba(11, 110, 130, 0.10);
  }

  &-items {
    @include media-breakpoint-up(lg) {
      grid-template-areas:  'A B'
                            'A C';
    }
  }

  &-item {
    &:first-child {
      grid-area: A;
    }

    &:nth-child(2) {
      grid-area: B;
    }

    &:nth-child(3) {
      grid-area: C;
    }

    &-icon {
      path {
        fill: $primary;
      }
    }

    &-button {
      height: 40px !important;
    }
  }
}

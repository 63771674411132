@import './bootstrap-modules';

.text-small {
  font-size: 12px !important;
}

.text-medium {
  font-size: 16px !important;
}

.text-sm-medium {
  @include media-breakpoint-up(sm) {
    font-size: 16px !important;
  }
}

.text-md-medium {
  @include media-breakpoint-up(md) {
    font-size: 16px !important;
  }
}

.text-lg-medium {
  @include media-breakpoint-up(lg) {
    font-size: 16px !important;
  }
}

.main-container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.inner-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

@mixin h1() {
  font-size: $h1-font-size !important;
  font-weight: $font-weight-medium !important;
  line-height: $h1-line-height !important;
}

@mixin h2() {
  font-size: $h2-font-size !important;
  font-weight: $font-weight-medium !important;
  line-height: $h2-line-height !important;
}

@mixin h3() {
  font-size: $h3-font-size !important;
  font-weight: $font-weight-medium !important;
  line-height: $h3-line-height !important;
}

@mixin h4() {
  font-size: $h4-font-size !important;
  font-weight: $font-weight-medium !important;
  line-height: 1.2 !important;
}

@mixin h5() {
  font-size: $h5-font-size !important;
  font-weight: $font-weight-medium !important;
  line-height: 1.2 !important;
}

@mixin h6() {
  font-size: $h6-font-size !important;
  font-weight: $font-weight-bold !important;
  line-height: 1.2 !important;
}


.h6 {
  @include h6();
}

.sm-h6 {
  @include media-breakpoint-up(sm) {
    @include h6();
  }
}

.md-h6 {
  @include media-breakpoint-up(md) {
    @include h6();
  }
}

.lg-h6 {
  @include media-breakpoint-up(lg) {
    @include h6();
  }
}

.text-medium2 {
  font-size: 18px !important;
}

.text-sm-medium2 {
  @include media-breakpoint-up(sm) {
    font-size: 18px !important;
  }
}

.text-md-medium2 {
  @include media-breakpoint-up(md) {
    font-size: 18px !important;
  }
}

.text-lg-medium2 {
  @include media-breakpoint-up(lg) {
    font-size: 18px !important;
  }
}

.h5 {
  @include h5();
}

.sm-h5 {
  @include media-breakpoint-up(sm) {
    @include h5();
  }
}

.md-h5 {
  @include media-breakpoint-up(md) {
    @include h5();
  }
}

.lg-h5 {
  @include media-breakpoint-up(lg) {
    @include h5();
  }
}

.h4 {
  @include h4();
}

.sm-h4 {
  @include media-breakpoint-up(sm) {
    @include h4();
  }
}

.md-h4 {
  @include media-breakpoint-up(md) {
    @include h4();
  }
}

.lg-h4 {
  @include media-breakpoint-up(lg) {
    @include h4();
  }
}

.text-main2 {
  font-size: 22px !important;
}

.text-sm-main2 {
  @include media-breakpoint-up(sm) {
    font-size: 22px !important;
  }
}

.text-md-main2 {
  @include media-breakpoint-up(md) {
    font-size: 22px !important;
  }
}

.text-lg-main2 {
  @include media-breakpoint-up(lg) {
    font-size: 22px !important;
  }
}

.h3 {
  @include h3();
}

.sm-h3 {
  @include media-breakpoint-up(sm) {
    @include h3();
  }
}

.md-h3 {
  @include media-breakpoint-up(md) {
    @include h3();
  }
}

.lg-h3 {
  @include media-breakpoint-up(lg) {
    @include h3();
  }
}

.h2 {
  @include h2();
}

.sm-h2 {
  @include media-breakpoint-up(sm) {
    @include h2();
  }
}

.md-h2 {
  @include media-breakpoint-up(md) {
    @include h2();
  }
}

.lg-h2 {
  @include media-breakpoint-up(lg) {
    @include h2();
  }
}

.h1 {
  @include h1();
}

.sm-h1 {
  @include media-breakpoint-up(sm) {
    @include h1();
  }
}

.md-h1 {
  @include media-breakpoint-up(md) {
    @include h1();
  }
}

.lg-h1 {
  @include media-breakpoint-up(lg) {
    @include h1();
  }
}

.text-large {
  font-size: 48px !important;
}

.text-lg-large {
  @include media-breakpoint-up(lg) {
    font-size: 48px !important;
  }
}

.text-large2 {
  font-size: 64px !important;
}

.text-lg-large2 {
  @include media-breakpoint-up(lg) {
    font-size: 64px !important;
  }
}

.font-weight-light {
  font-weight: $font-weight-light !important;
}

.font-weight-normal {
  font-weight: $font-weight-base !important;
}

.font-weight-medium {
  font-weight: $font-weight-medium !important;
}

.font-weight-bold {
  font-weight: $font-weight-bold !important;
}

.font-weight-md-normal {
  @include media-breakpoint-up(md) {
    font-weight: $font-weight-base !important;
  }
}

.font-weight-md-bold {
 @include media-breakpoint-up(md) {
   font-weight: $font-weight-bold !important;
 }
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-1_2 {
  line-height: 1.2 !important;
}

.line-height-1_3 {
  line-height: 1.3 !important;
}

.line-height-1_4 {
  line-height: 1.4 !important;
}

.line-height-sm-1_4 {
  @include media-breakpoint-up(sm) {
    line-height: 1.4 !important;
  }
}

.line-height-1_5 {
  line-height: 1.5 !important;
}

.rounded-2 {
  border-radius: 2px !important;
}

.rounded-4 {
  border-radius: 4px !important;
}

.z-10 {
  z-index: 10;
}

.fill-primary {
  path {
    fill: $primary;
  }
}

.break-spaces {
  white-space: break-spaces;
}

.rounded-large {
  border-radius: 8px;
}

.text-two-lines {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

@import "src/customization/bootstrap-modules.scss";

.competency-model {
  &-image {
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: auto;
      height: 530px;
    }
  }
}

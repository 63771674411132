@import "src/customization/bootstrap-modules.scss";

.trafory-possibilities {
  &-items {
    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);

      grid-gap: 16px;
    }

    @include media-breakpoint-up(lg) {
      grid-gap: 24px;
    }
  }

  &-item {
    &-icon {
      @include media-breakpoint-up(md) {
        width: 24px;
        height: 24px;
      }
    }
  }
}

@import "src/customization/bootstrap-modules.scss";

.mobile-app-info {
  background-color: $primary;

  &-links {
    @include media-breakpoint-up(sm) {
      max-width: 623px;
    }

    @include media-breakpoint-up(md) {
      max-width: 720px;
    }

    @include media-breakpoint-up(lg) {
      max-width: 1018px;
    }

    &-item {
      &-image {
        height: 320px;

        @include media-breakpoint-up(lg) {
          height: 532px;
        }
      }

      &-link {
        height: 58px;

        @include media-breakpoint-up(lg) {
          height: 80px;
        }
      }
    }
  }

  &-image-mobile {
    height: 320px;
  }
}

@import "src/customization/bootstrap-modules";

.technical-capabilities {
  &-icon-left {
    left: -60px;
    top: -30px;
    width: 102px;
    height: 102px;

    @include media-breakpoint-up(md) {
      width: 145px;
      height: 145px;

      left: 145px;
      top: 40px;
    }
  }

  &-icon-right {
    width: 93px;
    height: 93px;
    right: -32px;
    bottom: -40px;

    @include media-breakpoint-up(md) {
      right: 140px;
      bottom: 42px;
    }

    path {
      fill: #1F8296;
      fill-opacity: 1;
    }
  }

  &-button.ant-btn.ant-btn-lg {
    height: 36px;

    @include media-breakpoint-up(md) {
      height: 64px;
    }
  }
}

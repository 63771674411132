@import "src/customization/bootstrap-modules.scss";

.your-benefits {
  &-tab {
    &-icon {
      min-width: 24px;
      min-height: 24px;
    }
  }

  .ant-collapse {
    background-color: transparent;
    border: none;

    &-header {
      padding: 0 !important;
    }

    &-content-box {
      padding: 0 !important;
    }

    &-item {
      border: none;
      margin-bottom: 14px;

      &-active {
        .ant-collapse-header {
          border-radius: 4px 4px 0 0;
          background-color: $primary;
        }

        .your-benefits-tab-label {
          color: $white;
        }

        svg path {
          fill: $white;
        }

        .ant-collapse-expand-icon {
          svg {
            transform: rotate(-90deg) !important;
          }
        }
      }
    }
  }

  .ant-collapse-expand-icon {
    margin-right: 20px;
    margin-top: 10px;

    svg {
      transform: rotate(90deg);
    }
  }

  &-tab {
    &-image {
    }
  }

  .your-benefits-tab-label.active {
    color: $white;
    background-color: $primary;

    svg path {
      fill: $white;
    }
  }
}

@import "src/customization/bootstrap-modules.scss";

.trafory-advantages {
  max-width: 1220px;

  &-content {
    box-shadow: 0 0 20px 0 rgba(11, 110, 130, 0.10);

    &-icon {
      path {
        fill: $dark-orange;
      }
    }
  }

  &-image {
    width: 328px;
    margin-bottom: 16px;

    @include media-breakpoint-up(sm) {
      margin-bottom: 24px;
    }

    @include media-breakpoint-up(lg) {
      width: 553px;
      margin-bottom: -30px !important;
    }
  }
}

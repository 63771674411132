@import "src/customization/bootstrap-modules.scss";

.instruments-info-block {
  &-item {
    flex-direction: column;

    @include media-breakpoint-up(sm) {
      flex-direction: row-reverse;

      &:nth-child(2n) {
        flex-direction: row;
      }
    }

    @include media-breakpoint-up(md) {
      gap: 8px;
    }

    @include media-breakpoint-up(lg) {
      gap: 54px;
    }

    &-description {
      line-height: normal;
    }

    &-image {
      width: 100%;

      @include media-breakpoint-up(sm) {
        width: 328px;
        height: auto;
      }

      @include media-breakpoint-up(md) {
        width: 447px;
      }
    }
  }
}

.ant-select-dropdown {
  padding: 16px !important;
  min-width: auto !important;
  width: auto !important;

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: rgba($primary, .10);
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled):not(.ant-select-item-option-selected) {
    background-color: transparent;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled):hover {
    background-color: rgba($primary, .04);
  }
}

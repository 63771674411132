@import "src/customization/bootstrap-modules.scss";

.blog-info {
  @include media-breakpoint-up(lg) {
    max-width: 1200px;
  }

  &-items {
    overflow-x: scroll;
    overflow-y: visible;
    gap: 44px;
    padding-right: 16px;
    min-height: 200px;

    &::-webkit-scrollbar {
      display: none;
    }

    @include media-breakpoint-up(md) {
      min-height: 300px;
      overflow-x: hidden;
      padding-right: 0;
    }
  }

  &-item {
    min-width: 220px;
    width: 220px;

    &-image {
      object-fit: cover;
      height: max-content;

      max-height: 200px;
    }

    @include media-breakpoint-up(md) {
      min-width: 371px;
      width: 371px;
    }
  }
}

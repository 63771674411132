@import "src/customization/bootstrap-modules";

.ant-drawer-content-wrapper {
  @include media-breakpoint-down(md) {
    width: 100% !important;
  }


  .ant-drawer {
    &-close {
      color: $primary;
      font-size: 25px;

      @include media-breakpoint-up(md) {
        font-size: 28px;
      }
    }

    &-header {
      padding: 8px 0;
      border-bottom: 0;
      box-shadow: 0 5px 5px rgba(51, 150, 170, 0.05);

      @include media-breakpoint-up(md) {
        box-shadow: none;
      }

      @include media-breakpoint-up(lg) {
        padding-right: 100px;
        padding-top: 28px;
        padding-bottom: 8px;
      }

      &-title {
        justify-content: flex-end;
      }
    }

    &-body {
      padding: 0 26px;

      @include media-breakpoint-up(md) {
        padding-left: 52px;
        padding-top: 0;
      }
    }
  }
}

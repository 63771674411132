@import "src/customization/bootstrap-modules";

.trafory-instruments {

  @include media-breakpoint-up(md) {
    &-cards, &-title {
      max-width: 1200px;
    }

    &-scroll-container {
      width: 100%;

      &::-webkit-scrollbar {
        display: none;
      }

      overflow-y: scroll;
      max-height: 590px;
    }
  }


  &-list-items {
    &-title {
      margin-left: -16px;

      @include media-breakpoint-up(md) {
        margin-left: -24px;
      }
    }

    li {
      line-height: 1.42;

      &::marker {
        font-size: 10px;
        font-variant-numeric: oldstyle-nums;

        @include media-breakpoint-up(md) {
          font-size: 15px;
        }
      }

      @include media-breakpoint-up(md) {
        line-height: 1.83;
      }
    }

    &-item-text {
      top: 2px;

      @include media-breakpoint-up(md) {
        top: 4px;
      }
    }
  }

 &-card-image {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 446px;
      height: 297px;
    }
  }
}

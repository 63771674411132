@import "src/customization/bootstrap-modules";

.trafory-partners {
  &-title {
    max-width: 1200px;

    @include media-breakpoint-up(md) {
      padding: 0 60px;
    }
  }

  &-icons {
    width: fit-content;

    &, &-inner {
      gap: 16px;

      @include media-breakpoint-up(md) {
        gap: 24px;
      }
    }

    &-card {
      border-radius: 4px;
      width: max-content;
      height: 72px;

      @include media-breakpoint-up(md) {
        width:  max-content;
        height: 130px;
      }

      &-icon {
        max-width: 80%;
        max-height: 80%;
      }
    }
  }

  &-icons-container {
    max-width: 1400px;
  }
}

@import "src/customization/bootstrap-modules.scss";

.promo {
  &-info-title {
    @include media-breakpoint-only(sm) {
      margin-right: -8px;
      margin-top: -2px;
      vertical-align: text-top;
      max-width: 45%;
    }
  }

  .main-screen-logo {
    @include media-breakpoint-up(sm) {
      width: 385px;
    }

    @include media-breakpoint-up(md) {
      width: 567px;
    }
  }

  &-offer-conditions {
    font-size: 22px;

    @include media-breakpoint-up(sm) {
      font-size: 32px;
    }
  }

  &-icon {
    width: 24px;
    height: 24px;

    @include media-breakpoint-up(md) {
      width: 32px;
      height: 32px;
      max-height: 32px;
    }

    path {
      fill: white;
    }
  }

  &-image {
    width: 328px;
    margin-right: auto;
    margin-left: auto;
    display: block;

    @include media-breakpoint-up(sm) {
      position: absolute;
      top: 32px;
      right: 24px;
    }

    @include media-breakpoint-up(md) {
      width: 374px;
      top: 48px;
      right: 48px;
    }

    @include media-breakpoint-up(lg) {
      width: 429px;
      top: 50px;
      right: 120px;
    }
  }

  &-items-container {
    width: 100vw;
    max-width: 1400px;
    margin-right: -16px;
    margin-left: -16px;

    @include media-breakpoint-up(sm) {
      margin-right: -24px;
      margin-left: -24px;
    }

    @include media-breakpoint-up(lg) {
      margin-right: -120px;
      margin-left: -120px;
    }
  }

  &-items {
    overflow-x: scroll;
    object-fit: cover;
    pointer-events: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-item-inner {
    width: 493px;
    min-height: 337px;
  }

  &-functional-title {
    font-size: 20px;
    max-width: 230px;
    text-align: center;

    @include media-breakpoint-up(sm) {
      max-width: none;
    }
  }

  &-bonus-title {
    @include media-breakpoint-only(sm) {
      bottom: -6px;
    }
  }
}

.main-screen.home-page {
  .main-screen-icons {
    top: 0;

    &-frame {
      top: 0;
      transition: opacity 1s;
      z-index: 0;

      &-0, &-1, &-2 {
        &-icon-1 {
          left: 16px;
          top: 191px;
          width: 32px;
          height: 32px;

          @include media-breakpoint-up(sm) {
            left: 268px;
            top: 12px;
          }

          @include media-breakpoint-up(md) {
            top: 251px;
            left: 380px;
            width: 45px;
            height: 45px;
          }

          @include media-breakpoint-up(lg) {
            top: 42px;
            left: 96px;
          }
        }

        &-icon-2 {
          display: none;

          @include media-breakpoint-up(md) {
            display: block;
            top: 351px;
            left: 52px;
          }

          @include media-breakpoint-up(lg) {
            top: 524px;
            left: 259px;
          }
        }

        &-icon-3 {
          right: 16px;
          top: 132px;
          left: auto;

          @include media-breakpoint-up(sm) {
            left: 68px;
            top: 300px;
          }

          @include media-breakpoint-up(md) {
            left: 105px;
            top: 505px;
            width: 53px;
            height: 53px;
          }

          @include media-breakpoint-up(lg) {
            top: 374px;
            left: 667px;
          }
        }

        &-icon-4 {
          display: none;

          @include media-breakpoint-up(md) {
            display: block;
            top: 57px;
            right: auto;
            left: 726px;
          }

          @include media-breakpoint-up(lg) {
            top: 241px;
            left: 1053px;
          }
        }

        &-icon-5 {
          right: auto;
          top: 30px;
          left: 32px;

          @include media-breakpoint-up(sm) {
            top: 60px;
            left: 461px;
            right: auto;
          }

          @include media-breakpoint-up(md) {
            right: auto;
            top: 530px;
            left: 666px
          }

          @include media-breakpoint-up(lg) {
            left: 586px;
            top: 35px
          }
        }

        &-icon-6 {
          display: none;

          @include media-breakpoint-up(md) {
            display: block;
            right: auto;
            left: 953px;
            top: 391px;
          }

          @include media-breakpoint-up(lg) {
            left: 972px;
            top: 60px;
          }
        }
      }
    }
  }
}

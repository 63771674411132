@import "src/customization/bootstrap-modules.scss";

@mixin set-geography-background ($locale: 'ru') {
  $main-url: '../../../public/images/user-geography/';

  background-image: url("#{$main-url}#{$locale}/xs/map.webp");

  @include media-breakpoint-up(sm) {
    background-image: url("#{$main-url}#{$locale}/sm/map.webp");
  }

  @include media-breakpoint-up(md) {
    background-image: url("#{$main-url}#{$locale}/md/map.webp");
  }

  @include media-breakpoint-up(lg) {
    background-image: url("#{$main-url}#{$locale}/lg/map.webp");
  }
}

.users-geography {
  margin-top: -143px;
  padding-bottom: 190%;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 0;

  @include set-geography-background();

  @include media-breakpoint-up(sm) {
    margin-top: -232px;
    padding-bottom: 98.5%;
  }

  @include media-breakpoint-up(md) {
    margin-top: -25px;
    padding-bottom: 86.5%;
  }

  @include media-breakpoint-up(lg) {
    background-size: 1383px auto;
    margin-top: -200px;
    background-position-x: right;
    padding-bottom: 1140px;
  }

  &-title {
    top: 184px;
    width: 100%;
    text-align: center;

    @include media-breakpoint-up(sm) {
      top: 278px;
      left: 24px;
      width: auto;
      text-align: start;
    }

    @include media-breakpoint-up(md) {
      top: 80px;
      left: 44px;
    }

    @include media-breakpoint-up(lg) {
      top: 280px;
      left: calc((100vw - 1440px) / 2 + 112px);
    }
  }
}

.en {
  .users-geography {
    @include set-geography-background(en);
  }
}

@import "src/customization/bootstrap-modules";

.landing-header {
  box-shadow: 0 5px 5px rgba(51, 150, 170, 0.05);
  height: 44px;
  line-height: inherit;
  padding: 0 16px;

  &.sticky-header {
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0 5px 5px rgba($black, 0.05);

    @include media-breakpoint-down(sm) {
      .ant-btn {
        padding: 6px 12px;
        font-size: 12px;
        height: 29px;
      }
    }

    @include media-breakpoint-up(md) {
      .trafory-logo {
        height: 39px;
        width: auto;
      }

      .landing-header-language-select {
        margin-left: auto;
        .ant-select-selection-item, &-arrow {
          color: $white !important;
        }
      }
    }
  }

  @include media-breakpoint-up(sm) {
    height: 80px;
    padding: 0 35px;
  }

  @include media-breakpoint-up(md) {
    height: 90px;
    padding: 0 20px;
  }

  @include media-breakpoint-up(lg) {
    padding: 0 120px;
  }

  &-menu-button {
    width: 44px !important;
    height: 44px;
  }

  &-language-select {
    width: 60px;
    cursor: pointer;

    .ant-select-selection-item {
      color: $primary;
      padding-right: 0 !important;
    }
  }

  &:not(.sticky-header) {
    .anticon-pause {
      svg {
        color: $primary;
      }
    }
  }

  &-menu-modal {
    @include media-breakpoint-down(sm) {
      width: 100% !important;
    }
  }
}

.sticky-header {
  transform: translateY(-100%);
  transition: transform .3s;
  z-index: 20;

  &-show {
    transform: translateY(0);
  }
}

.ant-dropdown-menu {
  padding: 16px !important;
  border-radius: 4px !important;

  &-item {
    padding: 4px 8px !important;
  }

  &-item-active, .selected-language {
    background-color: $primary-4 !important;

    &:active {
      background-color: $primary-10 !important;
    }
  }
}

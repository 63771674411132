@import "src/customization/bootstrap-modules.scss";

.selected-post {
  white-space: break-spaces;
  font-size: 16px;
  word-wrap: break-word;

  @include media-breakpoint-up(md) {
    font-size: 22px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    color: $primary;

    @include media-breakpoint-up(md) {
      font-weight: bold;
    }
  }

  p {
    margin-bottom: 8px;
  }

  img {
    width: 100%;
    display: block;
    margin: 16px auto;

    @include media-breakpoint-up(sm) {
      width: auto;
      height: 218px;
      align-self: center;
    }

    @include media-breakpoint-up(md) {
      height: 370px;
    }
  }
}

@import "src/customization/bootstrap-modules.scss";

.blog-content {
  &-tags {
    grid-template-areas:  'a a'
                          'b c'
                          'd d';

    grid-template-rows: 200px 156px 200px;
    grid-template-columns: 1fr 1fr;

    grid-gap: 16px;

    @include media-breakpoint-up(sm) {
      grid-template-rows: 260px 192px 260px;
    }

    @include media-breakpoint-up(md) {
      grid-template-areas:  'a b'
                            'a c'
                            'd d';

      grid-template-rows: 272px 272px 365px;
      grid-template-columns: 60% 1fr;

      grid-gap: 24px;
    }

    @include media-breakpoint-up(lg) {
      grid-template-areas:  'a b c'
                            'a d d';

      grid-template-rows: 275px 275px;
      grid-template-columns: 50% 1fr 1fr;
    }

    &-item {
      background-size: 150%;
      background-position: center;
      background-repeat: no-repeat;

      @include media-breakpoint-up(sm) {
        background-size: 100%;
      }

      @include media-breakpoint-up(md) {
        background-size: 150%;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,.2);
        z-index: 0;
      }

      &:first-child {
        grid-area: a;
      }

      &:nth-child(2) {
        grid-area: b;
      }

      &:nth-child(3) {
        grid-area: c;
      }

      &:nth-child(4) {
        grid-area: d;
      }
    }
  }

  .trafory-logo {
    width: 307px;
    height: auto;

    @include media-breakpoint-up(md) {
      width: 509px;
    }

    @include media-breakpoint-up(lg) {
      width: 567px;
    }

    path {
      fill: $primary;
    }
  }
}

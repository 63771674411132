@import "src/customization/bootstrap-modules.scss";

.technical-capabilities-info-block {
  &-item {
    @include media-breakpoint-up(md) {
      &:not(:last-child) {
        margin-bottom: 46px !important;
      }
    }

   &-icon {
     path {
       fill: $dark-orange
     }
   }
  }

  &-image {
    width: 328px;

    @include media-breakpoint-up(md) {
      width: 420px;
    }

    @include media-breakpoint-up(lg) {
      width: 689px;
    }
  }
}
